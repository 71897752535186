<template>
  <div class="v-checkbox" @click.stop="toggleCheckbox">
    <div :class="[
      'v-checkbox__status',
      { 'v-checkbox__status-checked': isChecked },
    ]">
      <IconCheck2 v-if="isChecked" class="v-checkbox__status-icon" />
    </div>

    <slot />
  </div>
</template>

<script>
import IconCheck2 from '@/assets/icons/check2.svg';

export default {
  name: 'VCheckbox',
  components: {
    IconCheck2,
  },

  props: {
    isChecked: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    toggleCheckbox() {
      this.$emit('toggle-checkbox');
    },
  },
}
</script>

<style lang="scss" scoped>
.v-checkbox {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 12px;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  cursor: pointer;

  .v-checkbox__status-icon {
    height: 11px;
    width: 11px;

    path {
      fill: #FFF !important;
    }
  }
}

.v-checkbox__status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  background-color: #FFF;
  border: 1px solid $borderColor;
  border-radius: 3px;
  transition: border .1s ease;

  .v-checkbox:hover & {
    border-color:$blue;

    @include dark-theme {
      border-color: $darkThemeBorderMiddle;
    }
  }

  @include dark-theme {
    background-color: $darkThemeBgTwo;
    border-color: $darkThemeBorderMiddle;
  }
}

.v-checkbox__status-checked {
  background-color: $blue;
}
</style>
